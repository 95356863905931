/* Mobile Compatibility */

/* The navigation bar on the bottom containing three icons */
.mobile--navbar {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 50px;
  display: none;
  align-items: center;
  width: calc(100% - 40px);
  background-color: var(--shadowBlack);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  justify-content: space-between;
  box-shadow: 0 10px 20px var(--shadowBlack);
  padding: 0 10px;
  z-index: 10001;
}

/* Icons on the navigation bar */
.mobile--navbar img {
  width: 25px;
}

/* PB Logo on the navigation bar */
.mobile--navbar img:nth-child(2) {
  width: 35px;
}

/* Popup container for the bottom toolbar  */
.mobile--shelf--container {
  position: fixed;
  bottom: 80px;
  left: 10px;
  right: 10px;
  height: 70px;
  z-index: 10000;
  width: calc(100% - 40px);
  transition: 0.3s;
}

/* Bottom toolbar containing the list of desktop windows buttons */
.mobile--shelf {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--shadowBlack);
  border-radius: 10px;
  padding: 0 10px;
  overflow-x: auto;
  box-shadow: 0 10px 20px var(--shadowBlack);
}

/* Individual dektop buttons */
.mobile--shelf .desktopbtn {
  margin-bottom: 0;
  margin-right: 5px;
  border-radius: 7.5px;
}

/* Highlight effect of mobile desktop button on hover */
.mobile--shelf .desktopbtn:hover {
  outline: none;
  background-color: var(--tintWhite);
}

/* Highlight effect of mobile desktop button on hover */
.mobile--shelf .desktopbtn:active {
  outline: none;
  background-color: var(--bgWhite);
  box-shadow: 0 10px 20px var(--shadowBlack);
}

.mobile--sidebar {
  position: fixed;
  bottom: 70px;
  top: 10px;
  right: -275px;
  height: auto;
  display: none;
  align-items: center;
  flex-direction: column;
  width: 250px;
  max-width: calc(100% - 20px);
  background-color: #031b20f1;
  border-radius: 10px;
  overflow-x: auto;
  z-index: 10002;
  transition: 0.3s;
  box-shadow: 0 10px 20px var(--shadowBlack);
  overflow: auto;
}

.mobile--sidebar hr {
  width: 80%;
  margin: 5px auto;
}



@media all and (max-width: 900px),
all and (max-aspect-ratio:1/1) {

  .homepage {
    height: 100%;
    margin-bottom: 80px;
  }

  .loadingscreen .skip {
    border-radius: 10px;
    font-size: 15px;
  }

  .loadingscreen h1 {
    font-size: 20px;
  }

  .taskbar {
    display: none;
  }

  .mobile--navbar,
  .mobile--shelf,
  .mobile--sidebar {
    display: flex;
  }

  .desktopbtn--container {
    display: none;
  }

  .desktopbtn {
    padding: 5px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
  }

  .desktopbtn--icon {
    width: 40px;
    margin: 0 10px;
  }

  .desktopbtn--name {
    font-size: 10px;
  }

  .notif--container {
    display: none;
  }

  .notif--main {
    flex-basis: fit-content;
    border-radius: 5px;
    grid-gap: 2.5px;
    min-height: 200px;
    max-height: auto;
  }

  .notif--main h2 {
    font-size: 17.5px;
    text-align: center;
  }

  .notif--main p {
    font-size: 15px;
    text-align: center;
  }

  .notif--main small {
    font-size: 12px;
    text-align: center;
  }

  .notif--list .notif--self {
    margin: 5px 10px;
    padding: 7.5px;
    flex-direction: column;
    background-color: #5757574a;
    border: none;
    border-radius: 4.5px;
  }


  .notif--heading {
    display: flex;
    height: fit-content;
    margin-bottom: 5px;
    align-items: center;
  }

  .notif--self hr {
    flex-grow: 1;
    margin: 1px 1px 10px;
  }

  .notif--self h4 {
    font-size: 14px;
    margin: 0;
  }

  .notif--self img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .notif--self p {
    margin: 2.5px;
    font-size: 13px;
    text-align: left;
  }

  .notif--self small {
    margin: 2.5px;
    font-size: 11px;
    margin-top: 5px;
  }

  .setting--container {
    display: none;
  }

  .taskbar--timer {
    margin: 10px;
  }

  .mobile--sidebar--social {
    display: flex;
    width: calc(100% - 20px);
    padding: 10px;
    justify-content: space-evenly;
  }

  .mobile--sidebar--social img {
    width: 35px;
  }

  .mobile--sidebar--statusquo {
    margin-top: auto;
    padding: 10px;
  }


  .titlescreen--title_container {
    flex-direction: column;
    transform: translate(-50%, -30%);
    top: 30%;
  }

  .titlescreen--title_container img {
    width: 50vw;
    max-width: 200px;
    height: auto;
  }

  .titlescreen--title_container h1 {
    font-size: 8vw;
  }

  .titlescreen--date {
    font-size: 17.5px;
    bottom: 190px;
  }

  .titlescreen--downarrow {
    width: 30px;
    left: calc(50% - 15px);
    bottom: 100px;
  }

  .titlescreen--register {
    font-size: 15px;
    padding: 0px;
    width: min(400px, 70%);
    backdrop-filter: blur(0px);
    bottom: 140px;
  }

  .titlescreen--register p {
    padding: 17.5px;
  }

  .titlescreen--register hr {
    margin: 10px 0px;
  }


  .home_infocards--container {
    display: flex;
    flex-direction: column;
  }

  .home_infocards--container div {
    padding: 25px;
  }

  .home_infocards--container h2 {
    font-size: 25px;
  }

  .home_infocards--container>div p {
    font-size: 14px;
    z-index: 10;
  }

  .home_infocards--container>div {
    backdrop-filter: blur(0px);
  }

  .home_infocards--2022 img,
  .home_infocards--about img,
  .home_infocards--contact img {
    z-index: -10;
  }

  .home_infocards--2022:hover img,
  .home_infocards--about:hover img,
  .home_infocards--contact:hover img {
    width: 150px;
  }

  .home_infocards--container>div:hover {
    transform: none;
  }

  footer {
    margin-bottom: 80px;
  }

  .advancement {
    width: calc(100vw - 30px);
    height: 50px;
    right: 0;
    border-radius: 10px;
  }

  .advancement h1 {
    font-size: 17.5px;
  }

  .advancement p {
    font-size: 14px;
  }

  .window {
    width: calc(100% - 20px);
    margin: 0 10px;
    height: calc(100% - 80px);
    border-radius: 10px;
  }

  .window--header {
    height: 30px;
  }

  .window--header h3 {
    font-size: 10px;
  }

  .window--header img {
    width: 20px;
    user-select: none;
  }


  .window--close {
    border-radius: 0 10px 0 0;
    padding: 7.5px 10px;
  }

  .window--content {
    height: calc(100% - 30px);
    top: 30px;
    border-radius: 0 0 10px 10px;
  }

  .explorer {
    display: block;
  }

  .explorer--sidebar {
    display: none;
  }

  .explorer--foldergrid {
    justify-content: flex-start;
  }

  .eventbtns {
    margin-bottom: 0px;
    margin-right: 0px;
    flex: 1 0 100px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 10px;
  }

  .eventbtns:hover {
    border-radius: 10px;
  }

  .eventbtns>img {
    width: 75px;
    /* max-height: 50px; */
    margin: 0px 5px 10px;
    user-select: none;
  }

  .eventbtns>p {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 11px;
    text-shadow: 1px 1px 3px var(--shadowBlack), -1px -1px 3px var(--shadowBlack), 1px -1px 3px var(--shadowBlack), -1px 1px 3px var(--shadowBlack);
  }

  .sponsorbtns {
    margin-bottom: 0px;
    margin-right: 0px;
    flex: 1 0 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 10px;
  }

  .sponsorbtns:hover {
    border-radius: 10px;
  }

  .sponsorbtns>img {
    width: 200px;
    /* max-height: 50px; */
    margin: 0px 5px 10px;
    user-select: none;
  }

  .sponsorbtns>p {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 14px;
    text-shadow: 1px 1px 3px var(--shadowBlack), -1px -1px 3px var(--shadowBlack), 1px -1px 3px var(--shadowBlack), -1px 1px 3px var(--shadowBlack);
  }

  .zipfile div {
    font-size: 15px;
  }

  .textfile {
    padding: 15px;
  }

  .zipfile {
    background-color: var(--bgWhite);
    color: var(--fontBlack);
    padding: 5px;
    border-radius: 0 0 10px 10px;
  }

  .textfile div {
    font-size: 15px;
  }

  .textfile h1 {
    font-size: 25px;
    margin: 10px;
  }

  .textfile ul,
  .textfile ol {
    padding-left: 25px;
  }


  .imageviewer a .downloadicon {
    width: 20px;
    padding: 0px;
    top: 30px;
    right: 0px;
  }

  .imageviewer .downloadicon:hover {
    background-color: var(--accentBgColor);
    backdrop-filter: none;
    box-shadow: none;
    border-radius: 10px;
  }

  .team {
    background-color: transparent;
    width: 100%;
    border-radius: 0 0 10px 10px;
  }

  .team h2 {
    font-size: 25px;
    margin: 15px 0 5px;
  }

  .team--category {
    border-radius: 0;
  }

  .team--category img {
    width: 20px;
  }

  .team--category hr {
    flex-grow: 1;
    margin: 10px;
  }

  .team--category p {
    margin: 0;
    font-size: 12px;
  }

  .team--boardcore--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .team--boardcore {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    flex: 1;
  }

  .team--boardcore div {
    width: 100%;
    margin-top: 10px;
    align-items: center;
    text-align: center;
  }

  .team--boardcore>img {
    width: 125px;
    height: 125px;
    margin-right: 0px;
    user-select: none;
    border-radius: 5px;
  }

  .team--boardcore h1 {
    margin: 0;
    font-size: 17px;

  }

  .team--boardcore h3 {
    margin: 10px 0;
    font-weight: lighter;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .team--boardcore hr {
    width: 50%;
    margin: 10px auto;
  }

  .team--boardcore p {
    margin: 0;
    font-size: 10px;
  }

  .team--membergrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    grid-auto-rows: 60px;
    grid-auto-flow: row;
    grid-gap: 10px;

    padding: 30px;
  }

  .team--membergrid p {
    font-size: 13px;
    border-radius: 7.5px;
  }

  .events {
    padding: 10px;
  }

  .events h1 {
    font-size: 25px;
  }

  .events--bg {
    opacity: 0.25;
    position: absolute;
    width: auto;
    height: auto;
    max-height: 75%;
    max-width: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  .events--fieldscontainer {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 5px;
  }

  .events--fieldscontainer div {
    margin: 5px;
    flex: 1;
    flex-basis: 120px;
    height: auto;
    width: calc(50% - 20px);
    border-radius: 5px;
    padding: 20px 0px;
    backdrop-filter: blur(0px);
  }

  .events--fieldscontainer img {
    width: 17.5vw;
    max-width: 80px;
    margin-bottom: 10px;
  }

  .events--fieldscontainer h2,
  .events--fieldscontainer h3,
  .events--fieldscontainer p {
    margin: 0;
    text-align: center;
  }

  .events--fieldscontainer p {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .events--fieldscontainer h2 {
    font-size: 18px;
  }

  .events--fieldscontainer h3 {
    font-size: 15px;
  }

  .events--fieldscontainer h4 {
    font-size: 13px;
  }

  .events--desc {
    padding: 5px;
    font-size: 14px;
  }

  .events--desc ul,
  .events--desc ol {
    padding-left: 20px;
  }


  .contact h1 {
    font-size: 25px;
  }

  .contact p {
    font-size: 14px;
  }

  .contact--grid {
    grid-template: repeat(3, auto) / 1fr;
    grid-gap: 10px;
  }

  .contact--grid div {
    border-radius: 5px;
  }

  .contact--grid div a {
    font-size: 13px;
  }

  .contact--grid div h2 {
    margin: 5px;
    font-size: 20px;
  }

  .contact--grid .contact--phone div {
    padding: 10px;
    text-align: center;
    margin: 10px;
  }

  .contact--grid .contact--phone div p {
    margin: 5px 0;
    font-size: 12px;
  }

  .contact--grid .contact--phone div p:nth-child(1) {
    margin: 5px 0;
    font-size: 14px;
  }

  .contact--grid div div {
    background-color: unset;
    border: unset;
    box-shadow: unset;
  }

  .contact--grid div div p {
    text-align: center;
    margin: 0px;
  }

  .contact--phone {
    grid-row: span 3;
  }

  .contact--socialgrid {
    display: grid;
    grid-auto-flow: column;
    grid-template: repeat(3, auto) / 30px auto;
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .contact--socialgrid img {
    width: 30px;
  }


  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}