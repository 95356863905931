.unknown {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: relative;
}

.pwdprompt {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.pwdprompt h2 {
  font-size: 40px;
  margin: 10px;
}

.pwdprompt input {
  width: calc(100% - 10px);
  text-align: center;
  padding: 10px 5px;
  background-color: var(--windowElementColor);
  color: var(--fontWhite);
  margin: 10px 0;
  font-size: 20px;
  font-family: "Ubuntu";
  outline: 2px solid var(--windowOutlineColor);
  border: none;

}

.pwdprompt input:focus {
  background-color: var(--fontGrey);
  color: var(--fontBlack);
}

.pwdprompt input:disabled {
  background-color: var(--bgGrey);
  color: var(--windowColor);
}

.pwdprompt button {
  margin: 0 auto;
  text-align: center;
  padding: 7.5px 5px;
  background-color: var(--bgGrey);
  color: var(--fontWhite);
  margin: 10px 0;
  font-size: 20px;
  font-family: "Ubuntu";
  cursor: pointer;
  border: none;
  outline: none;
}

.pwdprompt button:hover {
  background-color: var(--PBorange);
}

.pwdprompt small {
  color: var(--alertRed);
}

.hint img {
  width: 200px;
}

@media all and (max-width: 900px) {}