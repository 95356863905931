/* 'Beta' part of ProjectBeta */
@font-face {
  font-family: "Code Bold";
  src: url("../fonts/Code\ Bold.otf");
}

/* 'Project' part of ProjectBeta */
@font-face {
  font-family: "Code Pro Light";
  src: url("../fonts/Code\ Pro\ Light.otf");
}

/* Default monospace font */
@font-face {
  font-family: "Ubuntu Mono";
  src: url("../fonts/UbuntuMono-Regular.ttf");
}

/* Default site-wide font */
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Regular.ttf");
}

/* Main HTML body and root CSS properties declarations */
html,
body {
  color: var(--fontWhite);
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: var(--accentBgColor);
  font-family: "Ubuntu";
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
}

/* Custom colors and properties */
:root {
  --PBorange: #cb4b16;
  --PBwhite: #eee8d5;
  --fontWhite: white;
  --fontGrey: #afafaf;
  --fontBlack: black;
  --bgWhite: white;
  --bgGrey: #494949;
  --bgBlack: black;
  --shadowBlack: #00000080;
  --shadowWhite: #ffffff28;

  --accentBgColor: #1b2c44;
  --tintWhite: #ffffff6f;
  --tintBlack: #00000049;
  --windowColor: #202020;
  --taskbarColor: #1d1d1d;
  --regBtnColor: ;
  --windowOutlineColor: #606060;
  --windowElementColor: #3434348e;

  --alertRed: #ff3131;
  --desktopIconFill: #7bc4ff41;
  --desktopIconBorder: #7bb4ffb9;
  --desktopIconFillActive: #7bc4ff6f;
  --desktopIconBorderActive: #7bb4ff;
}

/* Background hidden behind the network dynamic background */
.background {
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0
}

/* Background of the loading screen */
.loadingscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1B2C44;
  display: flex;
  z-index: 10000000;
  transition: 1s;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Animated PB Logo in the loading screen */
.loadingscreen video {
  width: 50vw;
  height: auto;
  max-width: 300px;
  user-select: none;
  display: none;
}

/* Static PB Logo in the loading screen */
.loadingscreen img {
  width: 35vw;
  height: auto;
  max-width: 200px;
  user-select: none;
  /* display: none; */
}

/* Div containing the static PB Logo and 'Please wait' text for Safari browsers */
.loadingscreen--safaricontainer {
  display: none;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

/* Skip button in the loading screen */
.loadingscreen .skip {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  padding: 10px 20px;
  transition: 0.5s;
  user-select: none;
  cursor: pointer;
  color: var(--shadowWhite);
  opacity: 0;
}

.loadingscreen .skip:hover {
  transition: 0.2s;
  opacity: 1;
  background-color: var(--bgWhite);
  color: var(--accentBgColor);
}

/* Fading out animation for the loading screen */
@keyframes loadingFadeOut {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

/* Taskbar */
.taskbar {
  width: 100%;
  background-color: var(--taskbarColor);
  height: 40px;
  position: fixed;
  object-position: center center;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10001;
}

/* PB logo on the far left of the taskbar */
.taskbar>.taskbar--logo {
  padding: 5px;
  margin: 0 5px;
  width: 30px;
}

/* All icons in the taskbar */
.taskbar a img,
.taskbar img {
  padding: 7.5px 10px;
  margin: 0 5px;
  width: 20px;
  user-select: none;
  cursor: pointer;
}

/* Search bar on the left of the taskbar */
.taskbar--searchbar {
  outline: 3px solid var(--windowOutlineColor);
  background-color: var(--bgGrey);
  width: 350px;
  height: 34px;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: auto;
  justify-content: flex-start;
}

/* Search icon inside the search bar */
.taskbar--searchbar img {
  margin: 0 5px;
  width: 20px;
}

/* Text written in the search bar */
.taskbar--searchbar p {
  margin: auto 0;
  width: fit-content;
  height: fit-content;
  color: var(--fontGrey);
}

/* Countdown timer in the taskbar */
.taskbar--timer {
  margin: 0 10px;
  padding: 0;
  font-family: "Ubuntu Mono";
}

/* Grid holding the file/folder icons in the desktop */
.desktopbtn--container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: fixed;
  margin: 10px;
  max-height: calc(100vh - 40px);
  flex-wrap: wrap;
  z-index: 1;
  padding-top: 10px;
}

/* Individual file/folder icons in the desktop */
.desktopbtn {
  margin-bottom: 20px;
  margin-right: 10px;
  width: 80px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: 1px solid transparent;
  user-select: none;
}

.desktopbtn:hover {
  background-color: var(--desktopIconFill);
  outline: 1px solid var(--desktopIconBorder);
}

.desktopbtn:active {
  background-color: var(--desktopIconFillActive);
  outline: 1px solid var(--desktopIconBorderActive);
}

/* Image part of the file/folder icon */
.desktopbtn--icon {
  width: 45px;
  margin: 0 auto;
  filter: drop-shadow(0 5px 2px var(--shadowBlack));
}

/* Name of the file/folder icon */
.desktopbtn--name {
  width: 100%;
  text-align: center;
  margin: 0;
  margin-top: 5px;
  z-index: 50;
  font-size: 12px;
  text-shadow: 1px 1px 3px var(--shadowBlack), -1px -1px 3px var(--shadowBlack), 1px -1px 3px var(--shadowBlack), -1px 1px 3px var(--shadowBlack);
}

/* Notification Panel Box */
.notif--container {
  position: fixed;
  bottom: 50px;
  right: 10px;
  background-color: var(--windowColor);
  width: 300px;
  height: fit-content;
  z-index: 10000;
  box-shadow: 0 10px 20px var(--taskbarColor);
  transition: 0.5s;
}

/* Notification Panel nested within the above box */
.notif--main {
  height: fit-content;
  max-height: 75vh;
  width: auto;
  margin: 10px;
  display: grid;
  grid-template-rows: 30px 25px 1fr;
  grid-template-columns: 1fr;
}

/* Notifications Panel Title */
.notif--main h2 {
  margin: 10px;
  font-size: 20px;
}

/* Text below Notifcation Panel Title i.e. "X New Notifications" */
.notif--main p {
  margin: 7.5px 10px 0;
  font-size: 15px;
}

/* Text below Notifcation Panel Title i.e. "X Total Notifications" */
.notif--main small {
  margin: 0px 10px 15px;
  font-size: 12px;
}

/* Div containing the list of all notifications */
.notif--list {
  overflow-y: auto;
}

/* Individual notification */
.notif--list .notif--self {
  margin: 15px 5px 0;
  height: fit-content;
  display: flex;
  padding: 10px;
  flex-direction: column;
  background-color: var(--windowElementColor);
  border: 1px solid var(--windowOutlineColor);
}

/* Header of notification */
.notif--heading {
  display: flex;
  height: fit-content;
  margin-bottom: 5px;
  align-items: center;
}

/* Highlighting of notification if it is new */
#notif--new {
  border: 1px solid var(--bgWhite);
  border-style: double;
  background-color: var(--shadowWhite);
}

.notif--self hr {
  flex-grow: 1;
  margin: 1px 1px 10px;
}

/* Notification Title */
.notif--self h4 {
  font-size: 15px;
  margin: 0;
}

/* PB Logo on left of each individual notification's header */
.notif--self img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  user-select: none;
}

/* Main content of the notification */
.notif--self p {
  margin: 5px;
}

/* Relative timestamp of notification */
.notif--self small {
  margin: 5px;
  margin-top: 15px;
  font-family: "Ubuntu Mono";
}

/* Settings Panel Box */
.setting--container {
  position: fixed;
  bottom: 50px;
  right: 10px;
  background-color: var(--windowColor);
  width: 300px;
  height: fit-content;
  z-index: 9999;
  box-shadow: 0 10px 20px var(--taskbarColor);
  transition: 0.5s;
}

/* Settings Panel Title */
.setting--main h2 {
  font-size: 20px;
  margin: 5px 0;
}

/* Settings Panel nested within in the above box */
.setting--main {
  height: fit-content;
  max-height: 75vh;
  width: auto;
  margin: 10px;
  padding: 10px;
  /* display: grid;
  grid-template-rows: 30px 25px 1fr;
  grid-template-columns: 1fr; */
}

/* Each individual setting */
.setting--option {
  margin: 20px 0;
}

/* Title of the setting */
.setting--option h4 {
  margin: 0;
}

/* Main description of the setting */
.setting--option p {
  margin: 0;
  font-size: 15px;
}

/* Text below Settings panel asking user to reload site to reset changes */
.setting--alert {
  text-align: center;
  display: inline;
}

/* General Buttons */
button {
  margin: 0 auto;
  text-align: center;
  padding: 7.5px 5px;
  width: 100%;
  background-color: var(--bgGrey);
  color: var(--fontWhite);
  margin: 10px 0;
  font-size: 16px;
  font-family: "Ubuntu";
  cursor: pointer;
  border: none;
  outline: none;
}

button:hover {
  background-color: var(--PBorange);
}

button:disabled {
  color: var(--fontGrey);
}

button:disabled:hover {
  background-color: var(--bgGrey);
}

/* All content above the taskbar i.e. the desktop */
.homepage {
  height: calc(100vh - 25px);
  width: 100%;
  position: fixed;
  overflow-y: auto;
  margin: 0;
}

/* Introduction screen appearing at the start of the page */
/* i.e. the PB logo and text, date, link buttons and down arrow*/
.titlescreen {
  width: 100%;
  height: calc(100vh - 40px);
  min-height: 600px;
  position: relative;
}

/* Div containing only the PB logo and text */
.titlescreen--title_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  margin: auto;
  user-select: none;
  z-index: 50;
}

/* PB Logo */
.titlescreen--title_container>img {
  width: 200px;
}

/* PB Text */
.titlescreen--title_container>h1 {
  margin: 40px;
  font-size: 70px;
}

/* 'Project' part of PB */
#h1_light {
  font-family: "Code Pro Light";
  font-weight: lighter;
  letter-spacing: -2.5px;
}

/* 'Beta' part of PB */
#h1_bold {
  font-family: "Code Bold";
}

/* Capsule link embedded buttons */
.titlescreen--register {
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  text-align: center;
  bottom: 110px;
  font-size: 20px;
  position: absolute;
  padding: 0px;
  background-color: var(--shadowWhite);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

/* Text on the capsule button */
.titlescreen--register p {
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  height: 100%;
  padding: 20px;
  flex: 1;
}

/* Left part of the capsule button */
.titlescreen--register p:nth-child(1) {
  border-radius: 50px 0 0 50px;
}

/* Right part of the capsule button */
.titlescreen--register p:nth-child(3) {
  border-radius: 0 50px 50px 0;
}

.titlescreen--register p:hover {
  background-color: var(--bgWhite);
  color: var(--fontBlack);
}

/* Vertical divider inside the capsule button */
.titlescreen--register hr {
  margin: 20px 0px;
  width: 0;
  height: auto;
  color: var(--bgWhite);
  background-color: var(--bgWhite);
  border-color: var(--bgWhite);
  outline-color: var(--bgWhite);
}

/* Date of commencement of event */
.titlescreen--date {
  color: var(--PBorange);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bolder;
  bottom: 170px;
  font-size: 22px;
  font-family: "Ubuntu Mono";
  width: 90vw;
  text-align: center;
}

/* Slowly oscillating clickable down arrow */
.titlescreen--downarrow {
  position: absolute;
  left: calc(50% - 20px);
  transform: translateX(-20px);
  bottom: 30px;
  width: 40px;
  opacity: 0.4;
  animation-name: idle_move;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  user-select: none;
}

/* Oscillatory motion of the down arrow */
@keyframes idle_move {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Div containing the three infocards at the bottom of the main page */
.home_infocards--container {
  height: fit-content;
  width: 85vw;
  margin: 0;
  padding: 100px 7.5vw;
  display: grid;
  grid-template: 2fr 1fr / 1fr 1fr;
  grid-gap: 25px;
}

/* Individual infocard */
.home_infocards--container>div {
  background-color: #00000049;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 15px;
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  outline: 1px solid var(--fontBlack);
  border-bottom: 10px solid var(--PBwhite);
}

.home_infocards--container>div:hover {
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.25);
  transform: translateY(-10px);
}

/* Title of the Infocard */
.home_infocards--container h2 {
  color: var(--PBwhite);
  font-size: 35px;
  font-family: "Ubuntu Mono";
  margin: 20px 0px;
}

/* General links */
a {
  color: var(--PBwhite);
  font-weight: bold;
}

a:hover {
  color: var(--PBorange);
  font-weight: bold;
}

footer {
  text-align: center;
  padding: 20px;
  opacity: 0.75;
}

/* Infocard description */
.home_infocards--container>div p {
  padding: 0px;
  font-size: 16px;
  text-align: justify;
}

/* Double the width of the Contact infocard */
.home_infocards--contact {
  grid-column: span 2;
}

/* Corner image of the infocards */
.home_infocards--container img {
  opacity: 0.5;
  position: absolute;
  width: 150px;
  top: 0;
  right: 0;
  user-select: none;
  transition: 0.3s;
}

/* Enlarge the corner image on hover */
.home_infocards--2022:hover img,
.home_infocards--about:hover img,
.home_infocards--contact:hover img {
  width: 200px;
}

/* Secret advancement banner mimicking the style of Minecraft advancements */
.advancement {
  position: fixed;
  background-color: var(--windowColor);
  right: 10px;
  width: 350px;
  height: 60px;
  border: 5px solid var(--windowOutlineColor);
  padding: 10px;
  display: flex;
  transition: 0.5s;
  top: -100px;
  opacity: 0;
  z-index: 10010;
}

/* Title of advancement */
.advancement h1 {
  font-size: 20px;
  margin: 5px;
  color: rgb(195, 122, 255);
}

/* Description of advancement */
.advancement p {
  margin: 5px;
}

/* Image at left of the advancement */
.advancement img {
  margin-right: 10px;
  user-select: none;
}

/* Draggable placeholders for the webpages */
.window {
  position: fixed;
  width: 55vw;
  height: 75vh;
  background-color: var(--windowColor);
  outline: 1px solid var(--windowOutlineColor);
  box-shadow: 0 7.5px 15px var(--shadowBlack);
  display: none;
}

/* Header of the window with PB logo, window title and close button */
.window--header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 40px;
  border-bottom: 1px solid var(--windowOutlineColor);
  display: flex;
  align-items: center;
}

.window--header>* {
  margin: 0 10px;
  user-select: none;
}

/* Window Title */
.window--header h3 {
  margin: 0;
  font-size: 15px;
  font-weight: normal;
}

/* PB logo and close icon */
.window--header img {
  width: 30px;
  user-select: none;
}

/* Close button */
.window--close {
  width: 15px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0px;
  cursor: pointer;
  padding: 12.5px 20px;
  transition: 0.2s;
  user-select: none;
}

.window--close:hover {
  background-color: var(--alertRed);
}

/* Portion of the window below the header */
.window--content {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  top: 40px;
  left: 0px;
}

/* Content of the window with ability to scroll */
.window--content>div {
  height: 100%;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 100%;
}

/* Explorer window mimicking the style of Windows Explorer */
.explorer {
  display: grid;
  grid-template: 1fr / 120px auto;
}

/* Sidebar on the left of the Explorer window */
.explorer--sidebar {
  border-right: 1px solid var(--windowOutlineColor);
  flex-direction: column;
  height: calc(100% - 20px);
  position: relative;
  padding: 10px;
  user-select: none;
}

/* Tabs inside the Explorer sidebar */
.explorer--sidebar>div {
  align-items: center;
  display: flex;
}

/* Tab text */
.explorer--sidebar>div>p {
  font-size: 12px;
  margin-left: 5px;
}

/* Tab image */
.explorer--sidebar>div>img {
  width: 18px;
}

/* Content inside the  */
.explorer--foldergrid {
  display: flex;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  flex-wrap: wrap;
}

/* File icons for department level events in Events folder */
.eventbtns {
  margin-bottom: 10px;
  margin-right: 10px;
  width: min(130px, 10vw);
  cursor: pointer;
  outline: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: min(10px, 0.8vw);
  user-select: none;
  text-align: center;
}

/* Highlight the icon with a half white square on hover  */
.eventbtns:hover {
  background-color: var(--shadowWhite);
}

/* Highlight the icon with a stronger white square on click  */
.eventbtns:active {
  background-color: var(--tintWhite);
}

/* Image of the icon button */
.eventbtns>img {
  width: min(90px, 7vw);
  margin: 0px auto 20px;
  user-select: none;
}

/* Name of the icon button */
.eventbtns>p {
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: min(12px, 1vw);
  text-shadow: 1px 1px 3px var(--shadowBlack), -1px -1px 3px var(--shadowBlack), 1px -1px 3px var(--shadowBlack), -1px 1px 3px var(--shadowBlack);
}

/* File icons for sponsor image links in Sponsors folder */
.sponsorbtns {
  margin-bottom: 10px;
  margin-right: 10px;
  width: min(130px, 10vw);
  cursor: pointer;
  outline: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: min(10px, 0.8vw);
  user-select: none;
  width: min(180px, 15vw);
}

/* Highlight the icon with a half white square on hover  */
.sponsorbtns:hover {
  background-color: var(--shadowWhite);
}

/* Highlight the icon with a stronger white square on click  */
.sponsorbtns:active {
  background-color: var(--tintWhite);
}

/* Image of the icon button */
.sponsorbtns img {
  width: min(180px, 15vw);
  margin: 0px auto 20px;
  user-select: none;
}

/* Name of the icon button */
.sponsorbtns>p {
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: min(13px, 1.2vw);
  text-shadow: 1px 1px 3px var(--shadowBlack), -1px -1px 3px var(--shadowBlack), 1px -1px 3px var(--shadowBlack), -1px 1px 3px var(--shadowBlack);
}

/* Text file window */
.textfile {
  padding: 20px;
  font-size: 17px;
}

/* Title text/ heading inside the text file */
.textfile h1 {
  text-align: center;
  font-size: 40px;
  margin: 30px 0 10px;
  color: var(--PBorange);
}

/* Lists */
.textfile ul,
.textfile ol {
  padding-left: 20px;
}

/* .zip file window with explicitly defined whie bg */
.zipfile {
  background-color: var(--bgWhite);
  color: var(--fontBlack);
  padding: 10px;
  font-family: "Ubuntu Mono";
  font-size: 20px;
}

/* Keeps each sentence / p element in separate lines */
.zipfile div {
  white-space: pre-line;
}

/* Keeps each sentence / p element in separate lines */
.zipfile div p {
  white-space: pre-line;
  margin: 0;
}

/* PDF viewer window */
.window--content .pdfcontainer {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* The nested element loading the actual PDF file */
.pdfviewer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Image viewer window */
.imageviewer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* The actual image loaded in the image viewer window */
.imageviewer img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: auto;
}

/* Download button for the image */
.imageviewer a .downloadicon {
  margin: 10px;
  position: fixed;
  right: 10px;
  top: 35px;
  width: 25px;
  height: auto;
  padding: 15px;
  opacity: 0.6;
}

/* Highlight the download button on hover */
.imageviewer .downloadicon:hover {
  opacity: 1;
  background-color: var(--accentBgColor);
  backdrop-filter: blur(3px);
  box-shadow: 0 10px 20px var(--shadowBlack);
}

/* Teams window */
.team {
  background-color: transparent;
}

/* Title text for the window */
.team h2 {
  text-align: center;
  font-size: 50px;
  margin: 30px 0 10px;
  color: var(--PBorange);
}

/* Sticky headers for title indicating categories of club members */
.team--category {
  padding: 0px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  user-select: none;
  position: sticky;
  top: 0px;
  transform: translateY(-2.5px);
  background-color: var(--windowColor);
  width: 100%;
}

/* The right arrow icon on the left of the sticky headers */
.team--category img {
  width: 30px;
}

/* The horizontal line in the sticky headers */
.team--category hr {
  flex-grow: 1;
  margin: 10px;
}

/* Text label indicating the category */
.team--category p {
  margin: 0;
}

/* Div containers for each individual Board & Core member's info card */
.team--boardcore {
  display: flex;
  margin: 35px 30px;
}

/* Text part of the above card */
.team--boardcore div {
  width: 70%;
}

/* Image part of the above card */
.team--boardcore>img {
  width: min(150px, 10vw);
  height: min(150px, 10vw);
  margin-right: 30px;
  user-select: none;
}

/* Name of the Board & Core member */
.team--boardcore h1 {
  margin: 0;
  font-size: min(32px, 2.6vw);
}

/* Designation of the Board / Core member */
.team--boardcore h3 {
  margin: 10px 0;
  font-weight: lighter;
  font-size: min(20px, 1.8vw);
  margin-bottom: 10px;
}

/* Separator between the designation and the quote of info card */
.team--boardcore hr {
  width: 50%;
  margin: 10px 0;
  background-color: var(--windowOutlineColor);
  border-color: transparent;
}

/* Quote of the Board / Core member */
.team--boardcore p {
  font-style: italic;
  color: var(--fontGrey);
  font-size: min(16px, 1.3vw);
  margin: 0;
}

/* Grid containing names of the regular club members */
.team--membergrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 70px;
  grid-auto-flow: row;
  grid-gap: 10px;
  padding: 30px;
}

/* Name of the individual club member */
.team--membergrid p {
  display: flex;
  padding: 5px;
  border: 1px solid var(--fontWhite);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

/* The department level events window */
.events {
  padding: 10px;
}

/* Title of the Event window containing the name */
.events h1 {
  text-align: center;
  color: var(--PBorange);
  z-index: 1000;
}

/* Background image of department icon of the window */
.events--bg {
  opacity: 0.25;
  position: absolute;
  width: auto;
  height: auto;
  max-height: 75%;
  max-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
}

/* Div containing the four info cards on the event */
.events--fieldscontainer {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  padding: 0;
  z-index: 1000;
}


/* Individual four event info cards */
.events--fieldscontainer div {
  height: min(200px, 15vw);
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--windowElementColor);
  border: 1px solid var(--windowOutlineColor);
  box-shadow: 0 10px 20px var(--shadowBlack);
  backdrop-filter: blur(5px);
  padding: min(20px, 1vw) 10px;
}

/* Infinity symbol shown for the 'Students per School' field */
.events--fieldscontainer div .infinity {
  width: min(8vw, 45px);
  margin-bottom: 5px;
}

/* Icon included with the individual event info card */
.events--fieldscontainer img {
  width: min(100px, 6vw);
  margin-bottom: 10px;
  user-select: none;

}

/* Text inside the event info card */
.events--fieldscontainer p,
.events--fieldscontainer h2,
.events--fieldscontainer h3,
.events--fieldscontainer h4 {
  margin: 2px;
  text-align: center;
}

/* Topic of the event info card */
.events--fieldscontainer p {
  margin-bottom: auto;
  padding-bottom: 5px;
  font-size: min(16px, 1vw);
}

/* Upper part of emphasised answer of the event info card */
.events--fieldscontainer h2 {
  margin-top: 15px;
  font-size: 30px;
  font-size: min(26px, 1.7vw);
}

/* Lower part of emphasied answer of the event info card */
.events--fieldscontainer h3 {
  font-size: min(19px, 1.2vw);
}

/* Regular answer of the event info card */
/* (Seen only for participation mode being hybrid) */
.events--fieldscontainer h4 {
  font-size: min(17.5px, 1.02vw);
}

/* Div containing the textual description of the event */
.events--desc {
  padding: 20px;
}

/* Contact window */
.contact {
  background-color: transparent;
  padding: 20px;
}

/* Title text of the contact window */
.contact h1 {
  text-align: center;
  font-size: 40px;
  margin: 0;
  color: var(--PBorange);
}

/* Grid defined for the various rectangular sections of the page */
.contact--grid {
  display: grid;
  grid-template-rows: 90px 1fr 150px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  grid-auto-columns: auto;
}

/* Restructures all grid elements into a vertical list */
@media screen and (max-width: 1300px) {
  .contact--grid {
    grid-template-columns: 1fr;
  }
}

/* Individual rectangular section */
.contact--grid div {
  background-color: var(--windowElementColor);
  border: 1px solid var(--windowOutlineColor);
  box-shadow: 0 10px 20px var(--shadowBlack);
  padding: 10px;
  text-align: center;
}

/* Heading of the rectangular section */
.contact--grid div h2 {
  margin: 5px;
}

/* Individual cards of the Phone section holding contact info of the Board members */
.contact--grid .contact--phone div {
  padding: 20px;
  text-align: center;
  margin: 10px;
  background-color: var(--windowElementColor);
  border: 1px solid var(--windowOutlineColor);
  box-shadow: 0 10px 20px var(--shadowBlack);
}

/* Text inside the contact cards */
.contact--grid .contact--phone div p {
  font-size: 14px;
}

/* Names of the members in the cards made bold */
.contact--grid .contact--phone div p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
}

/* Prevents the grid inside the Socials section to form a border around it */
/* similar to the contact cards in the Phone section */
.contact--grid div div {
  background-color: unset;
  border: unset;
  box-shadow: unset;
}

/* Removes general padding from the text inside the contact cards*/
.contact--grid div div p {
  text-align: center;
  margin: 0px;
}

/* Phone section taking 3 vertical cells in a 3x2 table */
.contact--phone {
  grid-row: span 3;
}

/* Grid defined inside the Socials section to hold the icon and text links  */
.contact--socialgrid {
  display: grid;
  grid-auto-flow: column;
  grid-template: repeat(3, 50px) / 50px 175px;
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Text inside the Socials section's grid */
.contact--socialgrid>* {
  text-align: left;
}

/* Icon for an individual social link */
.contact--socialgrid img {
  width: 40px;
  user-select: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar:hover {
  width: 25px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track:hover {
  background: rgb(53, 53, 53);
}

::-webkit-scrollbar-thumb {
  background: rgb(76, 76, 76);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(127, 127, 127);
}

::selection {
  background-color: var(--PBorange);
  color: var(--fontWhite);
}

.homepage::-webkit-scrollbar {
  width: 0px;
}

.homepage::-webkit-scrollbar-track {
  width: 0px;
}